body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif; /* Set the default font family */
  overflow-y: auto; /* Ensure vertical scrolling */
}

@font-face {
  font-family: 'Roboto'; /* Define the custom font family name */
  src:  url('Fonts/monday_banana.ttf') format('truetype');
}
.navbar {
  background-color: #0b5345;
  color: #fff;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar img {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 10rem;
  width: 4rem;
  height: 4rem;
}
.menu-icon {
  cursor: pointer;
  display: none; /* Hide initially on larger screens */
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
}

.gradient-container {
  height: cover;
  background: linear-gradient(to bottom,  #0b5345 0%, #229954 100%);
  overflow: hidden;
  transition: background 0.5s ease;
}

.content-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.grid-item {
  background-color: rgba(43, 29, 14, 1);
  padding: 10px;
  font-size: 2rem;
  text-align: center;
  border-radius: 2rem;
  color: white;
  border: 8px solid #000; /* Black border */
}

.grid-item a {
  color: #f1c40f; /* Set the color to green (replace with your desired color) */
  text-decoration: none; /* Optional: Remove underline from the link */
}

.grid-item img {
  padding: 20px;
  text-align: center;
  border-radius: 2rem;
  color: black;
}

/* Additional styles for your content or navbar can be added here */

/* Add this to your styles.css */
.additional-content {
  text-align: center;
  padding: 20px;
  font-size: 2.5rem;
  color: white;
}

.additional-content p {
  margin-bottom: 5px;
  padding-top: 10px;
  /*    background-image: url('visuals/istockphoto-1197239452-612x612.jpg'); /* Set the background image */
  /*    background-size: cover; /* Cover the entire container */
  /*    background-position: center; /* Center the background image */
  border-radius: 2.5rem;
}

.additional-content h2 {
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  color:  #f1c40f;
  text-shadow: 2px 2px 2px rgba( 135, 54, 0 , 1);

  /*    background-image: url('visuals/istockphoto-1197239452-612x612.jpg'); /* Set the background image */
  /*    background-size: cover; /* Cover the entire container */
  /*    background-position: center; /* Center the background image */
  border-radius: 2.5rem;
}


.additional-content img {
  max-width: 100%; /* Ensure the image doesn't exceed the container width */
  height: auto; /* Maintain the aspect ratio of the image */
  margin-top: 10px; /* Add margin for spacing */
  border-radius: 2rem;
}

/* Add this to your styles.css */

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 30px;
  transition: color 0.3s ease; /* Add transition effect for color change */
}

.nav-links a:hover {
  color: #f1c40f; /* Change color on hover */
}

/* Additional styles for your content or navbar can be added here */

/* Add this to your styles.css */

@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #333;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-links.show-menu {
    display: flex;
  }

  .nav-links a {
    padding: 15px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease; /* Add transition effect for background color change */
  }

  .nav-links a:hover {
    background-color: #555; /* Change background color on hover */
  }
}

.wavy {
  /* mask created with
  https://css-generators.com/wavy-shapes/ */

  background:linear-gradient(to bottom,    #4e342e 0%,  #873600 100%);
  --mask:
          radial-gradient(50.39px at 50% 68.75px,#000 99%,#0000 101%) calc(50% - 50px) 0/100px 51% repeat-x,
          radial-gradient(50.39px at 50% -43.75px,#0000 99%,#000 101%) 50% 25px/100px calc(51% - 25px) repeat-x,
          radial-gradient(50.39px at 50% calc(100% - 68.75px),#000 99%,#0000 101%) calc(50% - 50px) 100%/100px 51% repeat-x,
          radial-gradient(50.39px at 50% calc(100% + 43.75px),#0000 99%,#000 101%) 50% calc(100% - 25px)/100px calc(51% - 25px) repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);

}

.corner {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #000; /* Set your background color */
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
}


.fa {
  padding: 1rem;
  font-size: 2rem;
  color: #f1c40f;
  text-shadow: 2px 2px 2px rgba( 135, 54, 0 , 1);
  width: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

.slider {
  height: 25rem;
  margin: auto;
  position: relative;
  width:100%;
  display: grid;
  place-items: center;
  overflow: hidden;

}

.slide-track {
  display: flex;
  width: calc(200px * 4);
  animation: scroll 60s linear infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(calc(-150px * 4));
  }
}

.slide {
  height: 200px;
  width: 200px;
  display: flex;
  align-items: right;
  padding: 5rem;
}

.slider::before,
.slider::after {
  background: linear-gradient(to right, rgba(43, 29, 14, 1) 10%, rgba(43, 29, 14, 0) 100%);
  content: '';
  height: 100%;
  position: absolute;
  width: 3rem;
  padding: 0.5rem;
  z-index: 2;

}

.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

/* Button styles */
.button {
  font-family: 'Roboto', sans-serif; /* Set the default font family */
  font-size: 2rem;
  display: inline-block;
  padding: 10px 20px;
  padding-bottom: 10px;
  text-decoration: none;
  color: #f1c40f; /* Text color */
  background-color:rgba( 135, 54, 0 , 1); /* Button background color */
  border: 2px solid rgba( 135, 54, 0 , 1); /* Button border color */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition on hover */

  /* Optional: Adjust other styles as needed */
}

/* Change button color on hover */
.button:hover {
  background-color: rgba(70, 25, 0, 1);
}


